import React, { useState } from "react";
import NukaCarousel from "nuka-carousel";
import ProductCard from "./product-card";
import { buildClassName, setImageParameters } from "../utils";
import { head } from "lodash";

interface CarouselProps {
  slides: {
    id: string;
    items: {
      id: string;
      locationNames?: string[];
      title?: string;
      tag?: string;
      description?: string;
      price?: number;
      promoPrice?: number;
      linkText?: string;
      linkUrl?: string;
      imageSrc?: string;
    }[];
  }[];
}

const Carousel: React.FC<CarouselProps> = ({ slides }) => {
  const [slideIndex, setSlideIndex] = useState(0);

  const handleNextClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    setSlideIndex((slideIndex + 1) % slides.length);
  };

  const handlePreviousClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    setSlideIndex(slideIndex === 0 ? slides.length - 1 : slideIndex - 1);
  };

  return (
    <div className="carousel">
      <NukaCarousel withoutControls slideIndex={slideIndex}>
        {slides.map((slide) => {
          const highlight = head(slide.items);
          const rest = slide.items.slice(1, 4);
          return (
            <div className="carousel__items" key={`carousel-slide-${slide.id}`}>
              <div className="carousel__highlight">
                <ProductCard
                  locationNames={highlight?.locationNames}
                  title={highlight?.title ?? ""}
                  tag={highlight?.tag}
                  description={highlight?.description}
                  price={{ defaultPrice: highlight?.price, promoPrice: highlight?.promoPrice }}
                  linkText={highlight?.linkText ?? ""}
                  linkUrl={highlight?.linkUrl ?? ""}
                  imageSrc={
                    highlight?.imageSrc
                      ? setImageParameters(highlight.imageSrc, {
                          width: 1600,
                          height: 700,
                          mode: "crop",
                        })
                      : ""
                  }
                  cardType="landscape"
                  showAllIcons={true}
                  useOriginalImage={true}
                />
              </div>
              <div className="carousel__grid">
                {rest.map((item) => (
                  <ProductCard
                    key={`carousel-slide-${item?.id}-card-${item.id}`}
                    locationNames={item?.locationNames}
                    title={item.title ?? ""}
                    tag={item?.tag}
                    description={item.description}
                    price={{ defaultPrice: item?.price, promoPrice: item?.promoPrice }}
                    linkText={item?.linkText ?? ""}
                    linkUrl={item?.linkUrl ?? ""}
                    imageSrc={
                      item?.imageSrc
                        ? setImageParameters(item.imageSrc, {
                            width: 366,
                            height: 366,
                            mode: "crop",
                          })
                        : ""
                    }
                    cardType="horizontal"
                    showAllIcons={true}
                    useOriginalImage={true}
                  />
                ))}
              </div>
            </div>
          );
        })}
      </NukaCarousel>
      <div className="carousel__controls">
        <button
          type="button"
          className="button button--arrow button--arrow-left"
          onClick={handlePreviousClick}
        ></button>
        <button type="button" className="button button--arrow" onClick={handleNextClick}></button>
      </div>
      <div className="carousel__indicators">
        {slides.map((slide, index) => (
          <div
            key={`carousel-dot-${slide?.id}`}
            onClick={() => setSlideIndex(index)}
            className={buildClassName([
              "carousel__indicator",
              slideIndex === index && "carousel__indicator--active",
            ])}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
