import { compact } from "lodash";
import React from "react";
import { buildClassName } from "../utils";

interface LabeledTextAreaProps {
  name: string;
  required?: boolean;
  value?: string | number;
  defaultValue?: string | number;
  label?: string;
  placeholder?: string;
  hasError?: boolean;
  extraClassName?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
}

const LabeledTextArea: React.FC<LabeledTextAreaProps> = ({
  name,
  required,
  value,
  defaultValue,
  label,
  placeholder,
  extraClassName,
  hasError,
  onChange,
  onBlur,
}) => {
  return (
    <label
      className={buildClassName(["form__group", extraClassName, hasError && "form__group--error"])}
    >
      <span className="form__label">{compact([label, required && "*"]).join(" ")}</span>
      <textarea
        name={name}
        required={required}
        className="form__input form__input--textarea"
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        defaultValue={defaultValue}
      />
    </label>
  );
};

export default LabeledTextArea;
