import React from "react";
import { compact } from "lodash";
import { TideItemForTeamMember } from "../../types";
import Copy from "../copy";
import TeamMember from "./team-member";

interface TeamProps {
  title?: string;
  introduction?: string;
  members?: TideItemForTeamMember[];
}

const Team: React.FC<TeamProps> = ({ title, introduction, members }) => {
  return (
    <section className="section">
      <Copy centered title={title ?? ""} description={introduction} />
      <div className="productcard-grid productcard-grid--4">
        <div className="productcard-grid__items">
          {members?.map((member) => (
            <TeamMember
              key={member.id}
              name={compact([
                member.content?.general?.firstName,
                member.content?.general?.lastName,
              ]).join(" ")}
              job={member.content?.general?.job ?? undefined}
              picture={member.content?.general?.picture?.url ?? undefined}
              expertise={member.content?.general?.expertise ?? undefined}
              destinations={member.content?.general?.destinations ?? undefined}
              email={member.content?.general?.email ?? undefined}
              phone={member.content?.general?.phone ?? undefined}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Team;
