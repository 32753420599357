import React, { useEffect, useState } from "react";
import GoogleMapReact, { fitBounds } from "google-map-react";
import Popup from "./popup";
import Marker from "./marker";
import { isEmpty, max, min } from "lodash";

export const API_KEY = "AIzaSyD9FhsgpnYloyOo0jLPfHmMtlu2W8ZY114";

interface MapProps {
  options?: GoogleMapReact.MapOptions;
  onMarkerClick?: (id: string) => void;
  markers: {
    id: string;
    lat: number;
    lng: number;
    active?: boolean;
    title?: string;
    text?: string;
    onClick?: (id: string) => void;
  }[];
  waypoints: {
    id: string;
    lat: number;
    lng: number;
  }[];
  showPopups?: boolean;
}

const Map: React.FC<MapProps> = (props) => {
  const [api, setApi] = useState<{ map: any; maps: any }>();
  const [route, setRoute] = useState<any>();

  const createMapOptions = () =>
    props.options ?? {
      panControl: false,
      gestureHandling: "none",
      draggableCursor: "default",
      streetViewControl: true,
      mapTypeControl: true,
      fullscreenControl: false,
    };

  const drawRoute = (
    waypoints: {
      id: string;
      lat: number;
      lng: number;
    }[] = []
  ) => {
    if (route) {
      route.setMap(null);
    }

    if (api) {
      const { map, maps } = api;
      const route = new maps.Polyline({
        path: waypoints.map((m) => ({ lat: m.lat, lng: m.lng })),
        map: map,
        geodesic: true,
        strokeColor: "#000",
        strokeOpacity: 0.5,
        strokeWeight: 3,
      });

      setRoute(route);
    }
  };

  const handleGoogleApiLoaded = (api: any) => {
    setApi(api);
  };

  useEffect(() => {
    drawRoute(props.waypoints);

    // Manual (re)centering example
    /*
    if (
      api &&
      typeof api.maps.LatLngBounds === "function" &&
      typeof api.maps.LatLng === "function" &&
      typeof api.map.fitBounds === "function"
    ) {
      const bounds = new api.maps.LatLngBounds();
      [...props.waypoints, ...props.markers].forEach((wp) => {
        bounds.extend(new api.maps.LatLng(wp.lat, wp.lng));
      });
      api.map.fitBounds(bounds, 50);
    }
    */
  }, [api, props.waypoints]);

  const allPoints = [...props.waypoints, ...props.markers];
  const { center, zoom } = fitBounds(
    {
      ne: {
        lat: max(allPoints.map((p) => p.lat)) ?? 0,
        lng: max(allPoints.map((p) => p.lng)) ?? 0,
      },
      sw: {
        lat: min(allPoints.map((p) => p.lat)) ?? 0,
        lng: min(allPoints.map((p) => p.lng)) ?? 0,
      },
    },
    { width: 300, height: 300 }
  );

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: API_KEY }}
      defaultCenter={center}
      defaultZoom={zoom}
      options={createMapOptions}
      yesIWantToUseGoogleMapApiInternals={true}
      onGoogleApiLoaded={handleGoogleApiLoaded}
    >
      {(props.markers ?? []).map((m) => (
        <Marker
          key={`marker_${m.id}-${m.lat}-${m.lng}`}
          lat={m.lat}
          lng={m.lng}
          id={m.id}
          title={m.title}
          active={m.active}
          onClick={props.onMarkerClick}
        />
      ))}
      {(props.markers ?? [])
        .filter((m) => m.active && !isEmpty(m.title))
        .map((m) => (
          <Popup key={`popup_${m.id}`} lat={m.lat} lng={m.lng} title={m.title} />
        ))}
    </GoogleMapReact>
  );
};

export default Map;
