import React, { useEffect, useState } from "react";
import { useLocation } from "@reach/router";
import AgentSearchMap from "./map";
import Icon from "../icon";
import Link from "../link";
import { AddressItem } from "./types";
import { isNil } from "lodash";
import { Trans, useI18next } from "gatsby-plugin-react-i18next";

interface AgentSearchProps {}

const AgentSearch: React.FC<AgentSearchProps> = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [activeAddressId, setActiveAddressId] = useState<number | undefined>(undefined);

  const [postalCode, setPostalCode] = useState<string | null>(params.get("postalCode"));
  const [addresses, setAddresses] = useState<AddressItem[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const { t } = useI18next();

  const fetchAgents = async (postalCode: string) => {
    const response = await fetch(
      `${process.env.TIDE_HOST}/api/web/travelagents?$filter=postalCode eq '${postalCode}' and types/any(x:x eq 1) and isStopSales eq false`,
      {
        headers: {
          "Api-Key": process.env.API_KEY as string,
        },
      }
    );

    if (!response.ok) {
      throw Error("Could not fetch agents");
    }

    const data = (await response.json()) as {
      count: number;
      items: AddressItem[];
    };

    setAddresses(data.items);
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formValue = formData.get("postalCode") as string | null;

    if (formValue && /\d{4}/.test(formValue)) {
      setErrorMessage(undefined);
      setPostalCode(formValue);
    } else {
      setErrorMessage(t("POSTALCODE_INVALID"));
      setPostalCode(null);
    }
  };

  useEffect(() => {
    if (postalCode) {
      fetchAgents(postalCode);
    } else {
      setAddresses([]);
    }
  }, [postalCode]);

  return (
    <section className="section">
      <div className="find-office">
        <div className="find-office__form">
          <form
            className="form"
            name="form-find-office"
            noValidate
            onSubmit={handleSubmit}
            onReset={() => {
              setErrorMessage(undefined);
              setPostalCode(null);
            }}
          >
            <div className="form__region">
              <div className="form__row form__row--inline">
                <div className="form__group form__group--70 form__group--md-60">
                  <label className="form__label">{t("POSTALCODE")} *</label>
                  <input
                    type="text"
                    className="form__input"
                    name="postalCode"
                    placeholder={t("SEARCH_BY_POSTALCODE")}
                    defaultValue={postalCode ?? ""}
                    required
                  />
                  <button
                    type="reset"
                    className="button button--clear"
                    title={t("DELETE")}
                  ></button>
                </div>
                <div className="form__group form__group--30 form__group--md-40">
                  <button type="submit" className="cta" title={t("SEARCH")}>
                    <Icon name="ui-search" />
                    <Trans>SEARCH</Trans>
                  </button>
                </div>
              </div>
            </div>
          </form>

          {errorMessage && (
            <div className="placeholder-message">
              <p>{errorMessage}</p>
            </div>
          )}

          {isNil(errorMessage) && addresses.length === 0 && (
            <div className="placeholder-message">
              <Icon name="ui-marker" />
              <h5>{t("NO_OFFICE_FOUND")}</h5>
              <p>{t("INSERT_POSTALCODE")}</p>
            </div>
          )}

          {addresses.length > 0 && (
            <div className="find-office__results">
              {addresses.map((result) => (
                <div className="find-office__result" key={result.id}>
                  <div className="find-office__result-header">
                    <Icon name="ui-marker" />
                    <h6 className="find-office__result-heading">{result.name}</h6>
                  </div>
                  <div className="find-office__result-address">
                    <a onClick={() => setActiveAddressId(result.id)} className="link link--plain">
                      {result.street} {result.number} {result.box}
                      <br />
                      {result.postalCode} {result.location}
                      <br />
                      <Trans>BELGIUM</Trans>
                    </a>
                  </div>
                  <div className="find-office__result-contact">
                    {result.phone && (
                      <Link
                        href={`tel:${result.phone}`}
                        title={t("CALL_US", { phone: result.phone })}
                        className="link link--plain link--ellipsis"
                      >
                        {result.phone}
                      </Link>
                    )}
                    <br />
                    {result.requestEmail && (
                      <Link
                        href={`mailto:${result.requestEmail}?subject=Travelworld%20info`}
                        title={result.requestEmail}
                        className="link link--plain link--ellipsis"
                      >
                        {result.requestEmail}
                      </Link>
                    )}
                    <br />
                    {result.website && (
                      <Link
                        href={
                          /^https?:\/\//.test(result.website)
                            ? result.website
                            : `https://${result.website}`
                        }
                        title={result.website}
                        className="link link--plain link--ellipsis"
                        target="_blank"
                        rel="noopener"
                      >
                        {result.website}
                      </Link>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <AgentSearchMap
          addresses={addresses.filter(
            (a) => typeof a.latitude !== "undefined" && typeof a.longitude !== "undefined"
          )}
          activeAddressId={activeAddressId}
          onMarkerClick={(id) => setActiveAddressId(id)}
        />
      </div>
    </section>
  );
};

export default AgentSearch;
