import GoogleMapReact, { fitBounds } from "google-map-react";
import { max, min } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import Icon from "../icon";
import Link from "../link";
import { API_KEY } from "../map";
import { AddressItem } from "./types";
import { useI18next } from "gatsby-plugin-react-i18next";

interface AgentSearchMapProps {
  addresses: AddressItem[];
  activeAddressId?: number;
  onMarkerClick: (id: number) => void;
}

interface MarkerProps extends AddressItem {
  lat: number;
  lng: number;
  active: boolean;
  onClick: (id: number) => void;
}

const Marker: React.FC<MarkerProps> = ({ active, onClick, ...address }) => {
  const { t } = useI18next();

  return (
    <div style={{ position: "relative" }}>
      <div
        className="find-office__marker"
        onClick={() => onClick(address.id)}
        style={{ transform: "translate(-50%, -100%)", cursor: "pointer" }}
      >
        <Icon name="ui-marker" />
      </div>
      {active && (
        <div className="infowindow" style={{ transform: "translate(-50%, -150%)", zIndex: 1 }}>
          <div className="infowindow__header infowindow__header--name">{address.name}</div>
          <div className="infowindow__address">
            {address.street} {address.number} {address.box}
            <br />
            {address.postalCode} {address.location}
            <br />
            België
          </div>
          <div className="infowindow__contact">
            {address.phone && (
              <Link
                href={`tel:${address.phone}`}
                title={t("CALL_US", { phone: address.phone })}
                className="link link--plain"
              >
                {address.phone}
              </Link>
            )}
            <br />
            {address.requestEmail && (
              <Link
                href={`mailto:${address.requestEmail}?subject=Travelworld%20info`}
                title={address.requestEmail}
                className="link link--plain"
              >
                {address.requestEmail}
              </Link>
            )}
            <br />
            {address.website && (
              <Link
                href={address.website}
                title={address.website}
                className="link link--plain"
                target="_blank"
                rel="noopener"
              >
                {address.website}
              </Link>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const AgentSearchMap: React.FC<AgentSearchMapProps> = ({
  addresses,
  activeAddressId,
  onMarkerClick,
}) => {
  const mapRef = useRef<HTMLDivElement>(null);

  const defaultCenter = { lat: 50.8503, lng: 4.3517 };
  const defaultZoom = 8;

  const [apiLoaded, setApiLoaded] = useState<boolean>(false);

  const [bounds, setBounds] = useState<{ center: { lat: number; lng: number }; zoom: number }>({
    center: defaultCenter,
    zoom: defaultZoom,
  });

  useEffect(() => {
    if (apiLoaded && mapRef.current) {
      const rect = mapRef.current.getBoundingClientRect();
      const multiplier = 0.75;

      const belgiumCoords = {
        ne: {
          lat: 51.54,
          lng: 6.61,
        },
        sw: {
          lat: 49.34,
          lng: 2.22,
        },
      };

      if (addresses.length > 0) {
        setBounds(
          fitBounds(
            {
              ne: {
                lat: max(addresses.map((a) => a.latitude)) ?? belgiumCoords.ne.lat,
                lng: max(addresses.map((a) => a.longitude)) ?? belgiumCoords.ne.lng,
              },
              sw: {
                lat: min(addresses.map((a) => a.latitude)) ?? belgiumCoords.sw.lat,
                lng: min(addresses.map((a) => a.longitude)) ?? belgiumCoords.sw.lng,
              },
            },
            { width: rect.width * multiplier, height: rect.height * multiplier }
          )
        );
      }
    }
  }, [addresses, apiLoaded]);

  const handleMarkerClick = (id: number) => {
    if (onMarkerClick) {
      onMarkerClick(id);
    }
  };

  return (
    <div className="find-office__map" ref={mapRef}>
      <GoogleMapReact
        center={bounds.center}
        zoom={bounds.zoom}
        bootstrapURLKeys={{ key: API_KEY }}
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        onGoogleApiLoaded={() => setApiLoaded(true)}
      >
        {addresses.map((address) => (
          <Marker
            key={address.id}
            lat={address.latitude ?? 0}
            lng={address.longitude ?? 0}
            {...address}
            active={address.id === activeAddressId}
            onClick={handleMarkerClick}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
};

export default AgentSearchMap;
