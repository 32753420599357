import { compact } from "lodash";
import React from "react";
import { buildClassName } from "../utils";

interface LabeledInputProps {
  name: string;
  type?: string;
  required?: boolean;
  value?: string | number;
  defaultValue?: string | number;
  min?: string | number;
  max?: string | number;
  label?: string;
  placeholder?: string;
  hasError?: boolean;
  extraClassName?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
}

const LabeledInput: React.FC<LabeledInputProps> = ({
  name,
  type,
  required,
  value,
  defaultValue,
  min,
  max,
  label,
  placeholder,
  extraClassName,
  hasError,
  onChange,
  onBlur,
}) => {
  return (
    <label
      className={buildClassName(["form__group", extraClassName, hasError && "form__group--error"])}
    >
      <span className="form__label">{compact([label, required && "*"]).join(" ")}</span>
      <input
        name={name}
        type={type ?? "text"}
        required={required}
        className="form__input"
        min={min}
        max={max}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        defaultValue={defaultValue}
      />
    </label>
  );
};

export default LabeledInput;
