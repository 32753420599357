import { graphql } from "gatsby";
import { head, compact, get, isEmpty, clamp, round } from "lodash";
import React from "react";
import {
  TideItemForIntroComponent,
  TideItemForMediaTextComponent,
  TideItemForRichMediaTextComponent,
  TideItemForMediaSectionComponent,
  TideItemForTwoColumnTextComponent,
  TideItemForThreeColumnComponent,
  TideItemForQuoteComponent,
  TideItem,
  TideItemForHotelListComponent,
  TideItemForRoundtripListComponent,
  TideItemForCarouselComponent,
  TideItemForRoundtripListItem,
  TideItemForHotelListItem,
  TideItemForInspirationListItem,
  TideItemForRoundtrip,
  TideItemForHotel,
  TideProduct,
  TideItemForInspirationListComponent,
  TideItemForCarouselSlide,
  TideItemForContactFormComponent,
  TideItemForContactForm,
  TideItemForTeamComponent,
  TideItemForAgentSearchComponent,
  TideItemForRegionListComponent,
  TideItemForRegionListItem,
  TideItemForRegion,
  Maybe,
  TideCountry,
} from "../types";
import {
  generateParameterizedProductPath,
  generateRegionPath,
  getChildTeamMembers,
  mapMinDurationType,
  setImageParameters,
} from "../utils";
import AgentSearch from "./agent-search";
import Carousel from "./carousel";
import ContactForm from "./contact-form";
import Copy from "./copy";
import Embed from "./embed";
import GalleryGrid from "./gallery-grid";
import Image from "./image";
import Intro from "./intro";
import Link from "./link";
import MediaSection from "./media-section";
import MediaText from "./media-text";
import ProductCard from "./product-card";
import ProductCardGrid from "./product-card-grid";
import Quote from "./quote";
import Section from "./section";
import Team from "./team";
import ThreeColumn from "./three-column";
import TwoColumnText from "./two-column-text";
import { parse } from "date-fns";
import { useI18next } from "gatsby-plugin-react-i18next";

interface PageComponentsProps {
  componentItems: TideItem[];
}

const PageComponents: React.FC<PageComponentsProps> = ({ componentItems }) => {
  const { t, language } = useI18next();
  const getCountryName = (country: Maybe<TideCountry> | undefined) =>
    country?.localizedNames?.find((ln) => ln?.languageCode === language)?.value || country?.name;

  const componentRenderers = {
    "Intro Component": (tideItem: TideItemForIntroComponent) => (
      <Section key={tideItem.id}>
        <Intro title={tideItem.content?.general?.title ?? ""}>
          <div dangerouslySetInnerHTML={{ __html: tideItem.content?.general?.text ?? "" }}></div>
        </Intro>
      </Section>
    ),
    "Media Text Component": (tideItem: TideItemForMediaTextComponent) => (
      <Section key={tideItem.id}>
        <MediaText
          reversed={Boolean(tideItem.content?.general?.reversed)}
          title={tideItem.content?.general?.title ?? ""}
          mediaComponent={
            (tideItem.content?.general?.images?.length ?? 0) > 1 ? (
              <GalleryGrid
                images={(tideItem.content?.general?.images ?? [])?.map((image) => ({
                  src: image?.url ?? "",
                }))}
              />
            ) : tideItem.content?.general?.images?.length === 1 ? (
              <Image type="16-9" src={head(tideItem.content?.general?.images)?.url ?? ""} />
            ) : undefined
          }
          caption={tideItem.content?.general?.caption ?? ""}
        >
          <div dangerouslySetInnerHTML={{ __html: tideItem.content?.general?.text ?? "" }} />
        </MediaText>
      </Section>
    ),
    "Rich Media Text Component": (tideItem: TideItemForRichMediaTextComponent) => (
      <Section key={tideItem.id}>
        <MediaText
          reversed={Boolean(tideItem.content?.general?.reversed)}
          title={tideItem.content?.general?.title ?? ""}
          mediaComponent={
            <Embed embedCode={tideItem.content?.general?.embedCode ?? ""} aspectRatio="16-9" />
          }
        >
          <div dangerouslySetInnerHTML={{ __html: tideItem.content?.general?.text ?? "" }} />
        </MediaText>
      </Section>
    ),
    "Media Section Component": (tideItem: TideItemForMediaSectionComponent) => (
      <Section key={tideItem.id}>
        <MediaSection
          caption={tideItem.content?.general?.caption ?? ""}
          images={(tideItem.content?.general?.images ?? [])?.map((image) => ({
            src: image?.url ?? "",
          }))}
        />
      </Section>
    ),
    "Two Column Text Component": (tideItem: TideItemForTwoColumnTextComponent) => (
      <Section key={tideItem.id}>
        <TwoColumnText
          highlight={tideItem.content?.general?.highlight ?? false}
          title={tideItem.content?.general?.title ?? ""}
          firstComponent={
            <div
              dangerouslySetInnerHTML={{ __html: tideItem.content?.general?.leftContent ?? "" }}
            />
          }
          secondComponent={
            <div
              dangerouslySetInnerHTML={{ __html: tideItem.content?.general?.rightContent ?? "" }}
            />
          }
        />
      </Section>
    ),
    "Three Column Component": (tideItem: TideItemForThreeColumnComponent) => (
      <Section key={tideItem.id}>
        <ThreeColumn
          columns={compact([
            tideItem.content?.leftColumn,
            tideItem.content?.middleColumn,
            tideItem.content?.rightColumn,
          ]).map((column) => ({
            title: column.title ?? "",
            text: column.text ?? "",
            images: column.images?.map((image) => ({ url: image?.url ?? "" })),
          }))}
        />
      </Section>
    ),
    "Quote Component": (tideItem: TideItemForQuoteComponent) => (
      <Quote key={tideItem.id} {...tideItem.content?.general} />
    ),
    "Region List Component": (tideItem: TideItemForRegionListComponent) => (
      <Section key={tideItem.id} id="regions">
        <Copy
          centered
          title={tideItem.content?.general?.title ?? ""}
          description={tideItem.content?.general?.intro ?? ""}
        />
        <ProductCardGrid
          columns={clamp(tideItem.childItems?.length ?? 0, 2, 4)}
          itemsComponent={
            <>
              {(tideItem.childItems as TideItemForRegionListItem[]).map((regionListItem) => {
                const region = regionListItem.content?.general?.region as TideItemForRegion | null;
                return (
                  <ProductCard
                    key={regionListItem.id}
                    tag={undefined}
                    locationNames={compact([region?.content?.general?.title])}
                    title={""}
                    imageSrc={
                      region?.content?.general?.headerImage?.url
                        ? setImageParameters(region.content.general.headerImage.url, {
                            width: 785,
                            height: 550,
                            mode: "crop",
                          })
                        : ""
                    }
                    description={region?.content?.description?.intro ?? undefined}
                    linkUrl={
                      regionListItem.content?.general?.buttonLink
                        ? "/" + regionListItem.content?.general?.buttonLink
                        : generateRegionPath(region)
                    }
                    linkText={regionListItem.content?.general?.buttonText ?? t("VIEW_THIS_REGION")}
                    showAllIcons={true}
                    useOriginalImage={true}
                  />
                );
              })}
            </>
          }
        />
      </Section>
    ),
    "Hotel List Component": (tideItem: TideItemForHotelListComponent) => (
      <Section key={tideItem.id} id="hotels">
        <Copy
          centered
          title={tideItem.content?.general?.title ?? ""}
          description={tideItem.content?.general?.intro ?? ""}
        />
        <ProductCardGrid
          columns={clamp(tideItem.childItems?.length ?? 0, 2, 4)}
          itemsComponent={
            <>
              {(tideItem.childItems as TideItemForHotelListItem[]).map((hotelListItem) => {
                const hotel = hotelListItem.content?.general?.hotel as TideItemForHotel | null;
                const product = hotel?.content?.general?.product as TideProduct | null;

                return (
                  <ProductCard
                    key={hotelListItem.id}
                    tag={hotelListItem.content?.general?.tag ?? undefined}
                    locationNames={compact([getCountryName(product?.country)])}
                    title={hotel?.content?.general?.title ?? ""}
                    price={{
                      defaultPrice: hotel?.content?.general?.estimatedPrice ?? undefined,
                      promoPrice: hotel?.content?.general?.estimatedPromoPrice ?? undefined,
                    }}
                    imageSrc={
                      hotel?.content?.general?.headerImage?.url
                        ? setImageParameters(hotel.content.general.headerImage.url, {
                            width: 785,
                            height: 550,
                            mode: "crop",
                          })
                        : ""
                    }
                    description={hotel?.content?.general?.estimatedStay ?? undefined}
                    linkUrl={generateParameterizedProductPath(
                      hotel,
                      [],
                      hotelListItem.content?.travelData?.fromDate
                        ? parse(
                            hotelListItem.content?.travelData?.fromDate,
                            "yyyy-MM-dd",
                            new Date()
                          )
                        : null,
                      hotelListItem.content?.travelData?.endDate
                        ? parse(
                            hotelListItem.content?.travelData?.endDate,
                            "yyyy-MM-dd",
                            new Date()
                          )
                        : null
                    )}
                    linkText={t("VIEW_JOURNEY")}
                    showAllIcons={true}
                    useOriginalImage={true}
                  />
                );
              })}
            </>
          }
          actionsComponent={
            !isEmpty(tideItem.content?.general?.buttonLink) ? (
              <Link href={"/" + tideItem.content?.general?.buttonLink} className="cta" internal>
                {tideItem.content?.general?.buttonText}
              </Link>
            ) : (
              <></>
            )
          }
        />
      </Section>
    ),
    "Roundtrip List Component": (tideItem: TideItemForRoundtripListComponent) => (
      <Section key={tideItem.id} id="roundtrips">
        <Copy
          centered
          title={tideItem.content?.general?.title ?? ""}
          description={tideItem.content?.general?.intro ?? ""}
        />
        <ProductCardGrid
          columns={clamp(tideItem.childItems?.length ?? 0, 2, 4)}
          itemsComponent={
            <>
              {(tideItem.childItems as TideItemForRoundtripListItem[]).map((roundtripItem) => {
                const roundtrip = roundtripItem.content?.general
                  ?.roundtrip as TideItemForRoundtrip | null;
                const product = roundtrip?.content?.general?.product as TideProduct | null;

                return (
                  <ProductCard
                    key={roundtripItem.id}
                    locationNames={compact([getCountryName(product?.country)])}
                    title={roundtrip?.content?.general?.title ?? ""}
                    description={roundtrip?.content?.general?.estimatedStay ?? undefined}
                    price={{
                      defaultPrice: roundtrip?.content?.general?.estimatedPrice ?? undefined,
                      promoPrice: roundtrip?.content?.general?.estimatedPromoPrice ?? undefined,
                    }}
                    imageSrc={
                      roundtrip?.content?.general?.headerImage?.url
                        ? setImageParameters(roundtrip.content.general.headerImage.url, {
                            width: 785,
                            height: 550,
                            mode: "crop",
                          })
                        : ""
                    }
                    linkUrl={generateParameterizedProductPath(
                      roundtrip,
                      [],
                      roundtripItem.content?.travelData?.fromDate
                        ? parse(
                            roundtripItem.content?.travelData?.fromDate,
                            "yyyy-MM-dd",
                            new Date()
                          )
                        : null,
                      roundtripItem.content?.travelData?.endDate
                        ? parse(
                            roundtripItem.content?.travelData?.endDate,
                            "yyyy-MM-dd",
                            new Date()
                          )
                        : null
                    )}
                    linkText={t("VIEW_JOURNEY")}
                    showAllIcons={true}
                    useOriginalImage={true}
                  />
                );
              })}
            </>
          }
          actionsComponent={
            !isEmpty(tideItem.content?.general?.buttonLink) ? (
              <Link href={"/" + tideItem.content?.general?.buttonLink} className="cta" internal>
                {tideItem.content?.general?.buttonText}
              </Link>
            ) : (
              <></>
            )
          }
        />
      </Section>
    ),
    "Inspiration List Component": (tideItem: TideItemForInspirationListComponent) => (
      <Section key={tideItem.id}>
        <Copy
          centered
          title={tideItem.content?.general?.title ?? ""}
          description={tideItem.content?.general?.intro ?? ""}
        />
        <ProductCardGrid
          columns={clamp(tideItem.childItems?.length ?? 0, 2, 4)}
          itemsComponent={
            <>
              {(tideItem.childItems as TideItemForInspirationListItem[]).map(
                (inspirationListItem) => (
                  <ProductCard
                    key={inspirationListItem.id}
                    cardType="skyscraper"
                    title={inspirationListItem.content?.general?.title ?? ""}
                    imageSrc={
                      inspirationListItem.content?.general?.image?.url
                        ? setImageParameters(inspirationListItem.content.general.image.url, {
                            width: 400,
                            height: 700,
                            mode: "crop",
                          })
                        : ""
                    }
                    linkUrl={inspirationListItem.content?.general?.buttonLink ?? "/"}
                    linkText={inspirationListItem.content?.general?.buttonText ?? ""}
                    useOriginalImage={true}
                    showAllIcons={true}
                  />
                )
              )}
            </>
          }
          actionsComponent={
            !isEmpty(tideItem.content?.general?.buttonLink) ? (
              <Link href={"/" + tideItem.content?.general?.buttonLink} className="cta" internal>
                {tideItem.content?.general?.buttonText}
              </Link>
            ) : (
              <></>
            )
          }
        />
      </Section>
    ),
    "Carousel Component": (tideItem: TideItemForCarouselComponent) => (
      <Section key={tideItem.id} extraClassName="section--carousel">
        <Copy
          centered
          title={tideItem.content?.general?.title ?? ""}
          description={tideItem.content?.general?.intro ?? ""}
        />
        <ProductCardGrid
          itemsComponent={
            <Carousel
              slides={((tideItem.childItems as TideItemForCarouselSlide[]) ?? [])?.map((slide) => ({
                id: slide?.id,
                items: ((slide?.childItems as TideItemForRoundtripListItem[]) ?? []).map((item) => {
                  const roundtrip = item.content?.general?.roundtrip as TideItemForRoundtrip | null;
                  const product = roundtrip?.content?.general?.product as TideProduct | null;
                  const minDurationType = mapMinDurationType(product?.minDurationType ?? undefined);

                  let minDurationLabel = undefined;
                  if (minDurationType === "hours") {
                    minDurationLabel = product?.minDuration
                      ? product.minDuration +
                        " " +
                        (product?.minDuration == 1 ? t("HOUR") : t("HOURS"))
                      : undefined;
                  } else if (minDurationType === "days") {
                    minDurationLabel = product?.minDuration
                      ? product.minDuration +
                        " " +
                        (product?.minDuration == 1 ? t("DAY") : t("DAYS"))
                      : undefined;
                  } else if (minDurationType === "nights") {
                    minDurationLabel = product?.minDuration
                      ? product.minDuration +
                        " " +
                        (product?.minDuration == 1 ? t("NIGHT") : t("NIGHTS"))
                      : undefined;
                  }
                  if (roundtrip?.content?.general?.estimatedStay) {
                    minDurationLabel = roundtrip?.content?.general?.estimatedStay;
                  }

                  return {
                    id: item.id,
                    title: roundtrip?.content?.general?.title ?? undefined,
                    tag: item.content?.general?.tag ?? undefined,
                    locationNames: compact([getCountryName(product?.country)]),
                    description: minDurationLabel,
                    price: roundtrip?.content?.general?.estimatedPrice ?? undefined,
                    promoPrice: roundtrip?.content?.general?.estimatedPromoPrice ?? undefined,
                    linkText: t("VIEW_JOURNEY"),
                    linkUrl: generateParameterizedProductPath(
                      roundtrip,
                      [],
                      item.content?.travelData?.fromDate
                        ? parse(item.content?.travelData?.fromDate, "yyyy-MM-dd", new Date())
                        : null,
                      item.content?.travelData?.endDate
                        ? parse(item.content?.travelData?.endDate, "yyyy-MM-dd", new Date())
                        : null
                    ),
                    imageSrc: roundtrip?.content?.general?.headerImage?.url ?? undefined,
                  };
                }),
              }))}
            />
          }
          actionsComponent={
            !isEmpty(tideItem.content?.general?.buttonLink) ? (
              <Link href={"/" + tideItem.content?.general?.buttonLink} className="cta" internal>
                {tideItem.content?.general?.buttonText}
              </Link>
            ) : (
              <></>
            )
          }
        />
      </Section>
    ),
    "Contact Form Component": (tideItem: TideItemForContactFormComponent) => {
      const linkedContactForm = tideItem?.content?.linkedForm?.form as TideItemForContactForm;
      return (
        <ContactForm
          key={tideItem.id}
          title={tideItem.content?.general?.title ?? undefined}
          emailMessage={linkedContactForm?.content?.internMail?.message ?? undefined}
          emailSubject={linkedContactForm?.content?.internMail?.subject ?? undefined}
          confirmationEmailTemplate={
            linkedContactForm?.content?.externalMail?.templateConfirmationMail?.tideId ?? NaN
          }
          newsletterTag={tideItem?.content?.tags?.newsletterTag?.tideId ?? NaN}
          contactTag={tideItem?.content?.tags?.contactTag?.tideId ?? NaN}
          confirmationText={tideItem?.content?.confirmation?.description ?? ""}
          confirmationTitle={tideItem?.content?.confirmation?.title ?? ""}
          privacyPath={tideItem?.content?.general?.privacyDisclaimerLink ?? ""}
          privacyText={tideItem?.content?.general?.privacyDisclaimerLinkText ?? ""}
        />
      );
    },
    "Team Component": (tideItem: TideItemForTeamComponent) => (
      <Team
        title={tideItem.content?.general?.title ?? undefined}
        introduction={tideItem.content?.general?.introduction ?? undefined}
        members={getChildTeamMembers(tideItem)}
        key={tideItem.id}
      />
    ),
    "Agent Search Component": (tideItem: TideItemForAgentSearchComponent) => (
      <React.Fragment key={tideItem.id}>
        <Copy
          centered
          title={tideItem.content?.general?.title ?? ""}
          description={tideItem.content?.general?.introduction ?? undefined}
        />
        <AgentSearch />
      </React.Fragment>
    ),
  };

  return (
    <>
      {componentItems?.map((tideItem) => {
        const renderer = get(componentRenderers, tideItem?.templateName ?? "");
        if (renderer !== undefined) {
          return renderer(tideItem);
        }
        return null;
      })}
    </>
  );
};

export default PageComponents;

export const query = graphql`
  fragment pageComponentsFragment on TideItem {
    childItems {
      id
      templateName
      ... on TideItemForIntroComponent {
        content {
          general {
            title
            text
          }
        }
      }
      ... on TideItemForMediaTextComponent {
        content {
          general {
            title
            text
            caption
            reversed
            images {
              url
            }
          }
        }
      }
      ... on TideItemForMediaSectionComponent {
        content {
          general {
            images {
              url
            }
            caption
          }
        }
      }
      ... on TideItemForRichMediaTextComponent {
        content {
          general {
            title
            text
            reversed
            embedCode
          }
        }
      }
      ... on TideItemForTwoColumnTextComponent {
        content {
          general {
            title
            leftContent
            rightContent
            highlight
          }
        }
      }
      ... on TideItemForThreeColumnComponent {
        content {
          leftColumn {
            title
            text
            images {
              url
            }
          }
          middleColumn {
            title
            text
            images {
              url
            }
          }
          rightColumn {
            title
            text
            images {
              url
            }
          }
        }
      }
      ... on TideItemForQuoteComponent {
        content {
          general {
            quote {
              ... on TideItemForQuote {
                content {
                  general {
                    title
                    body
                    signature
                    author
                    job
                    image {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }

      ... on TideItemForContactFormComponent {
        id
        name
        content {
          general {
            introText
            title
            privacyDisclaimerLink
            privacyDisclaimerLinkText
          }
          confirmation {
            description
            title
          }
          tags {
            newsletterTag {
              tideId
              name
            }
            contactTag {
              tideId
              name
            }
          }
          linkedForm {
            form {
              ... on TideItemForContactForm {
                id
                name
                content {
                  externalMail {
                    templateConfirmationMail {
                      tideId
                    }
                  }
                  internMail {
                    message
                    subject
                  }
                }
              }
            }
          }
        }
      }

      ... on TideItemForCarouselComponent {
        content {
          general {
            title
            intro
            buttonText
            buttonLink
          }
        }
        childItems {
          ... on TideItemForCarouselSlide {
            id
            childItems {
              ... on TideItemForRoundtripListItem {
                id
                name
                content {
                  general {
                    roundtrip {
                      ... on TideItemForRoundtrip {
                        name
                        content {
                          general {
                            title
                            headerImage {
                              url
                            }
                            estimatedPrice
                            estimatedPromoPrice
                            estimatedStay
                            product {
                              country {
                                name
                                localizedNames {
                                  languageCode
                                  value
                                }
                              }
                              minDuration
                              minDurationType
                            }
                          }
                        }
                        ...productPathFragment
                      }
                    }
                    tag
                  }
                  travelData {
                    fromDate
                    endDate
                  }
                }
              }
            }
          }
        }
      }
      ... on TideItemForRegionListComponent {
        name
        content {
          general {
            title
            intro
          }
        }
        childItems {
          id
          ... on TideItemForRegionListItem {
            name
            content {
              general {
                region {
                  ... on TideItemForRegion {
                    name
                    content {
                      general {
                        title
                        headerImage {
                          url
                        }
                      }
                      description {
                        intro
                      }
                    }
                    parentItem {
                      ...countryOrRegionPathFragment
                    }
                  }
                }
                buttonText
                buttonLink
              }
            }
          }
        }
      }
      ... on TideItemForHotelListComponent {
        name
        content {
          general {
            title
            intro
            buttonText
            buttonLink
          }
        }
        childItems {
          id
          ... on TideItemForHotelListItem {
            name
            templateName
            content {
              general {
                hotel {
                  ... on TideItemForHotel {
                    name
                    content {
                      general {
                        title
                        headerImage {
                          url
                        }
                        estimatedPrice
                        estimatedPromoPrice
                        estimatedStay
                        product {
                          country {
                            name
                            localizedNames {
                              languageCode
                              value
                            }
                          }
                          minDuration
                          minDurationType
                        }
                      }
                    }
                    ...productPathFragment
                  }
                }
                tag
              }
              travelData {
                fromDate
                endDate
              }
            }
          }
        }
      }
      ... on TideItemForRoundtripListComponent {
        content {
          general {
            title
            intro
            buttonText
            buttonLink
          }
        }
        childItems {
          id
          ... on TideItemForRoundtripListItem {
            name
            content {
              general {
                roundtrip {
                  ... on TideItemForRoundtrip {
                    name
                    content {
                      general {
                        title
                        headerImage {
                          url
                        }
                        estimatedPrice
                        estimatedPromoPrice
                        estimatedStay
                        product {
                          country {
                            name
                            localizedNames {
                              languageCode
                              value
                            }
                          }
                          minDuration
                          minDurationType
                        }
                      }
                    }
                    ...productPathFragment
                  }
                }
              }
              travelData {
                fromDate
                endDate
              }
            }
          }
        }
      }
      ... on TideItemForInspirationListComponent {
        content {
          general {
            title
            intro
            buttonText
            buttonLink
          }
        }
        childItems {
          id
          ... on TideItemForInspirationListItem {
            name
            content {
              general {
                title
                image {
                  url
                }
                buttonText
                buttonLink
              }
            }
          }
        }
      }
      ... on TideItemForTeamComponent {
        content {
          general {
            title
            introduction
          }
        }
        childItems {
          id
          ... on TideItemForTeamMember {
            name
            templateName
            content {
              general {
                destinations
                email
                expertise
                firstName
                job
                lastName
                phone
                picture {
                  url
                  altText
                  title
                }
              }
            }
          }
        }
      }
      ... on TideItemForAgentSearchComponent {
        content {
          general {
            title
            introduction
          }
        }
      }
    }
  }

  fragment productPathFragment on TideItem {
    ... on TideItemForRoundtrip {
      name
      content {
        general {
          title
          slug
          estimatedPrice
          estimatedPromoPrice
        }
      }
    }
    ... on TideItemForHotel {
      name
      content {
        general {
          title
          slug
          estimatedPrice
          estimatedPromoPrice
        }
      }
    }
    parentItem {
      ...countryOrRegionPathFragment
    }
  }

  fragment countryOrRegionPathFragment on TideItem {
    templateName
    ... on TideItemForCountry {
      name
      content {
        general {
          country {
            tideId
          }
          title
          slug
        }
      }
    }
    ... on TideItemForRegion {
      name
      content {
        general {
          region {
            tideId
          }
          title
          slug
        }
      }
    }
    parentItem {
      templateName
      ... on TideItemForCountry {
        name
        content {
          general {
            country {
              tideId
            }
            title
            slug
          }
        }
      }
      ... on TideItemForRegion {
        name
        content {
          general {
            region {
              tideId
            }
            title
            slug
          }
        }
      }
      parentItem {
        templateName
        ... on TideItemForDestinations {
          name
          content {
            general {
              title
              slug
            }
          }
        }
        parentItem {
          ... on TideItemForDestinations {
            name
            content {
              general {
                title
                slug
              }
            }
          }
        }
      }
    }
  }
`;
