import React from "react";
import { setImageParameters } from "../../utils";
import Icon from "../icon";
import Image from "../image";
import Link from "../link";
import { useI18next } from "gatsby-plugin-react-i18next";

interface TeamMemberProps {
  name: string;
  picture?: string;
  job?: string;
  expertise?: string;
  destinations?: string;
  phone?: string;
  email?: string;
}

const TeamMember: React.FC<TeamMemberProps> = ({
  picture,
  name,
  job,
  expertise,
  destinations,
  phone,
  email,
}) => {
  const { t } = useI18next();
  return (
    <div className="teamcard">
      <div className="teamcard__header">
        {picture && (
          <Image
            src={setImageParameters(picture, { width: 100, height: 100, mode: "crop" })}
            alt={name}
          />
        )}
        <div className="teamcard__name-function">
          <h5 title="Lieven Bossaert">{name}</h5>
          {job && (
            <p className="teamcard__function" title="Travel Expert">
              {job}
            </p>
          )}
        </div>
      </div>
      <div className="teamcard__body">
        {expertise && (
          <div className="teamcard__column">
            {t("EXPERTISE")}:
            <div dangerouslySetInnerHTML={{ __html: expertise }} />
          </div>
        )}
        {destinations && (
          <div className="teamcard__column">
            {t("DESTINATIONS")}:
            <div dangerouslySetInnerHTML={{ __html: destinations }} />
          </div>
        )}
      </div>

      <div className="teamcard__footer">
        {email && (
          <Link
            href={`mailto:${email}?subject=Travelworld%20info`}
            title={email}
            className="link link--plain"
          >
            <Icon name="social-email" />
            {email}
          </Link>
        )}
        {phone && (
          <Link href={`tel:${phone}`} title={phone} className="link link--plain">
            <Icon name="social-phone" />
            {phone}
          </Link>
        )}
      </div>
    </div>
  );
};

export default TeamMember;
