import React from "react";
import { buildClassName } from "../utils";

interface EmbedProps {
  embedCode: string;
  aspectRatio?: "16-9" | "4-3";
}

const Embed: React.FC<EmbedProps> = ({ embedCode, aspectRatio }) => (
  <div
    className={buildClassName(["embed", aspectRatio && `embed--${aspectRatio}`])}
    dangerouslySetInnerHTML={{ __html: embedCode }}
  />
);

export default Embed;
