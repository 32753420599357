import React from "react";
import { buildClassName } from "../utils";

interface TwoColumnTextProps {
  title?: string;
  firstComponent?: React.ReactElement;
  secondComponent?: React.ReactElement;
  highlight?: boolean;
}

const TwoColumnText: React.FC<TwoColumnTextProps> = ({
  title,
  firstComponent,
  secondComponent,
  highlight,
}) => (
  <div className={buildClassName(["two-column", highlight && "two-column--highlight"])}>
    <div className="two-column__container">
      <div className="two-column__title">
        <h2>{title}</h2>
      </div>
      <div className="two-column__column">{firstComponent}</div>
      <div className="two-column__column">{secondComponent}</div>
    </div>
  </div>
);

export default TwoColumnText;
