import React, { useState } from "react";
import { head } from "lodash";
import Image from "../image";
import Lightbox from "react-image-lightbox";
import { useI18next } from "gatsby-plugin-react-i18next";

interface ColumnProps {
  title?: string;
  text?: string;
  images?: {
    url?: string;
  }[];
}

const Column: React.FC<ColumnProps> = ({ title, text, images = [] }) => {
  const [open, setOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const { t } = useI18next();

  return (
    <div className="three-column__item">
      <Image
        type="golden-ratio"
        title={t("VIEW_IMAGE")}
        src={head(images)?.url}
        alt="Travelworld"
        count={images.length > 1 ? images.length - 1 : undefined}
        onLinkClick={(e) => {
          e.preventDefault();
          setOpen(true);
        }}
      />
      <h3 className="three-column__heading">{title}</h3>
      <div className="three-column__description" dangerouslySetInnerHTML={{ __html: text ?? "" }} />
      {open && images.length > 0 && (
        <Lightbox
          imageCaption={`${imageIndex + 1} / ${images.length}`}
          mainSrc={images[imageIndex]?.url ?? ""}
          nextSrc={images[(imageIndex + 1) % images.length]?.url}
          prevSrc={images[(imageIndex + images.length - 1) % images.length]?.url}
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() => setImageIndex((imageIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setImageIndex((imageIndex + 1) % images.length)}
        />
      )}
    </div>
  );
};

export default Column;
