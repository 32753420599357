import React from "react";

interface GalleryImageProps {
  src: string;
  alt?: string;
  index?: number;
  onClick?: (index: number) => void;
}

const GalleryImage: React.FC<GalleryImageProps> = ({ src, alt, index, onClick }) => {
  const handleClick: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
    if (onClick && typeof index !== "undefined") {
      event.preventDefault();
      onClick(index);
    }
  };

  return (
    <div className="image">
      <a href={src} title={alt} className="image__link" onClick={handleClick}>
        <img src={src} alt={alt} className="image__content" />
      </a>
    </div>
  );
};

export default GalleryImage;
