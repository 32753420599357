import React from "react";

interface PopupProps {
  title?: string;
  lat: number;
  lng: number;
}

const Popup: React.FC<PopupProps> = ({ title }) => (
  <div className="map-popup">
    <div className="map-popup__anchor">
      <div className="map-popup__bubble">{title}</div>
    </div>
  </div>
);

export default Popup;
