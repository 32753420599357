import React from "react";
import Column from "./column";

interface ThreeColumnProps {
  columns?: {
    title?: string;
    text?: string;
    images?: {
      url?: string;
    }[];
  }[];
}

const ThreeColumn: React.FC<ThreeColumnProps> = ({ columns }) => (
  <div className="three-column">
    <div className="three-column__items">
      {columns?.map((column, index) => (
        <Column {...column} key={`column-${index}-${column.title}`} />
      ))}
    </div>
  </div>
);

export default ThreeColumn;
