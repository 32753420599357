import React from "react";
import { setImageParameters } from "../utils";

interface QuoteProps {
  image?: string;
  title?: string;
  body?: string;
  signature?: string;
  author?: string;
  job?: string;
}

const Quote: React.FC<QuoteProps> = ({ image, title, body, signature, author, job }) => (
  <section className="section">
    <div className="quote">
      <div className="quote__content">
        {image && (
          <div className="image">
            <img
              src={setImageParameters(image, { width: 125, height: 125, mode: "crop" })}
              alt={author}
              className="image__content"
            />
          </div>
        )}
        {title && <h3 className="quote__heading">{title}</h3>}
        <div className="quote__body">{body}</div>
        {signature && <div className="quote__signature">{signature}</div>}
        {author && <div className="quote__name">{author}</div>}
        {job && <div className="quote__function">{job}</div>}
      </div>
    </div>
  </section>
);

export default Quote;
