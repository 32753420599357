import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { requestContact, requestCrmcontact } from "../../tide-api";
import { processPlaceholders } from "../../utils";
import LabeledInput from "../labeled-input";
import LabeledTextArea from "../labeled-text-area";
import LabeledCheckbox from "../labeled-checkbox";
import { get, isNil } from "lodash";
import flat from "flat";
import { Trans, useI18next } from "gatsby-plugin-react-i18next";

interface ContactFormProps {
  title?: string;
  introText?: string;
  emailSubject?: string;
  emailMessage?: string;
  newsletterTag?: number;
  contactTag?: number;
  confirmationEmailTemplate?: number;
  privacyPath?: string;
  privacyText?: string;
  confirmationTitle?: string;
  confirmationText?: string;
}

const getSubjectFromUrl = () => {
  if (typeof window !== "undefined") {
    return new URLSearchParams(window.location.search).get("subject") ?? "";
  }
};

const ContactForm: React.FC<ContactFormProps> = ({
  title,
  introText,
  emailSubject,
  emailMessage,
  confirmationEmailTemplate,
  newsletterTag,
  contactTag,
  privacyPath,
  privacyText,
  confirmationTitle,
  confirmationText,
}) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const openForm = () => {
    setFormSubmitted(false);
  };
  const { t } = useI18next();

  const subject = getSubjectFromUrl();
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      telNr: "",
      departureDate: "",
      message: !isNil(subject) ? t("SUBJECT", { subject }) : "",
      preferences: "",
      adults: 1,
      children: 0,
      rooms: 1,
      gender: "",
      acceptTerms: false,
      newsletter: false,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required(t("FIRST_NAME_REQUIRED")),
      lastName: Yup.string().required(t("LAST_NAME_REQUIRED")),
      email: Yup.string().email(t("EMAIL_ADDRESS_INVALID")).required(t("EMAIL_REQUIRED")),
      telNr: Yup.string().required(t("PHONE_NUMBER_REQUIRED")),
      departureDate: Yup.string().required(t("DEPARTURE_DATE_REQUIRED")),
      preferences: Yup.string().required(t("PREFERENCES_REQUIRED")),
      adults: Yup.number().required(t("NUMBER_OF_ADULTS_REQUIRED")),
      children: Yup.number().required(t("NUMBER_OF_CHILDREN_REQUIRED")),
      rooms: Yup.number().required(t("NUMBER_OF_ROOMS_REQUIRED")),
      gender: Yup.string().required(t("GENDER_REQUIRED")),
      acceptTerms: Yup.bool().oneOf([true], t("PLEASE_AGREE_WITH_TERMS")),
    }),
    onSubmit: async (values) => {
      if (newsletterTag && contactTag && emailMessage && emailSubject) {
        const tags = [contactTag];

        if (values.newsletter) {
          tags.push(newsletterTag);
        }

        const replacements = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phone: values.telNr,
          message: values.message,
          adults: values.adults.toString(),
          children: values.children.toString(),
          rooms: values.rooms.toString(),
          preferences: values.preferences,
          departureDate: values.departureDate,
        };

        const subject = processPlaceholders(emailSubject, replacements);
        const message = processPlaceholders(emailMessage, replacements);

        Promise.all([
          requestCrmcontact({
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            remark: values.message,
            phone: values.telNr,
            tags: tags,
            confirmationEmailTemplate: confirmationEmailTemplate,
          }),
          requestContact({
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phone: values.telNr,
            subject: subject,
            message: message,
          }),
        ])
          .then(() => {
            setFormSubmitted(true);

            if (typeof window !== "undefined") {
              window.scrollTo({ top: 0 });
            }
          })
          .catch((error) => {
            console.error("error", error);
          });
      }
    },
  });

  const flatErrors: Record<string, string> = flat(formik.errors);
  const hasVisibleError = (key: string) => get(formik.errors, key) && get(formik.touched, key);

  return (
    <section className="section">
      <div className="contact-form">
        <div className="contact-form__form">
          {!formSubmitted ? (
            <div className="form-state">
              <div className="contact-form__intro">
                <h2 className="contact-form__title">{title}</h2>
                <p>{introText}</p>
              </div>
              <form
                noValidate
                className="form"
                onSubmit={formik.handleSubmit}
                name="form--contact"
                id="form--contact"
              >
                <div className="form__region">
                  <div className="form__row">
                    <div
                      className={
                        hasVisibleError("gender") ? "form__group form__group--error" : "form__group"
                      }
                    >
                      <div className="radiobutton-group">
                        <div className="radiobutton">
                          <label className="radiobutton__label">
                            <input
                              type="radio"
                              value="male"
                              name="gender"
                              className="radiobutton__input"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              defaultChecked={formik.values.gender === "male"}
                            />
                            {t("MR")}
                          </label>
                        </div>

                        <div className="radiobutton">
                          <label className="radiobutton__label">
                            <input
                              type="radio"
                              value="female"
                              name="gender"
                              className="radiobutton__input"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              defaultChecked={formik.values.gender === "female"}
                            />
                            {t("MS")}
                          </label>
                        </div>

                        <div className="radiobutton">
                          <label className="radiobutton__label">
                            <input
                              type="radio"
                              value="X"
                              name="gender"
                              className="radiobutton__input"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              defaultChecked={formik.values.gender === "X"}
                            />
                            {t("OTHER")}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form__row">
                    <LabeledInput
                      name="firstName"
                      label={t("FIRST_NAME")}
                      required
                      placeholder={t("FIRST_NAME")}
                      extraClassName="form__group--sm-50"
                      hasError={hasVisibleError("firstName")}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.firstName}
                    />
                    <LabeledInput
                      name="lastName"
                      label={t("LAST_NAME")}
                      required
                      placeholder={t("LAST_NAME")}
                      extraClassName="form__group--sm-50"
                      hasError={hasVisibleError("lastName")}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.lastName}
                    />
                    <LabeledInput
                      type="email"
                      name="email"
                      label={t("EMAIL")}
                      required
                      placeholder={t("EMAIL_ADDRESS")}
                      extraClassName="form__group--sm-50"
                      hasError={hasVisibleError("email")}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    <LabeledInput
                      name="telNr"
                      label={t("PHONE")}
                      required
                      placeholder={t("PHONE_NUMBER")}
                      extraClassName="form__group--sm-50"
                      hasError={hasVisibleError("telNr")}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.telNr}
                    />
                  </div>
                </div>
                <div className="form__region">
                  <div className="form__row">
                    <LabeledInput
                      type="date"
                      name="departureDate"
                      label={t("PREFERRED_DEPARTURE_DATE")}
                      required
                      placeholder={t("CHOOSE_PREFERRED_DEPARTURE")}
                      hasError={hasVisibleError("departureDate")}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.departureDate}
                    />
                    <LabeledInput
                      type="number"
                      extraClassName="form__group--sm-50 form__group--md-33"
                      name="adults"
                      label={t("ADULTS")}
                      required
                      hasError={hasVisibleError("adults")}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.adults}
                    />
                    <LabeledInput
                      type="number"
                      extraClassName="form__group--sm-50 form__group--md-33"
                      name="children"
                      label={t("CHILDREN")}
                      required
                      hasError={hasVisibleError("children")}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.children}
                    />
                    <LabeledInput
                      type="number"
                      extraClassName="form__group--sm-50 form__group--md-33"
                      name="rooms"
                      label={t("ROOMS")}
                      required
                      hasError={hasVisibleError("rooms")}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.rooms}
                    />
                    <LabeledInput
                      name="preferences"
                      label={t("PREFERENCES")}
                      required
                      placeholder={t("PREFERENCES_PLACEHOLDER")}
                      hasError={hasVisibleError("preferences")}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.preferences}
                    />
                  </div>
                </div>
                <div className="form__region">
                  <div className="form__row">
                    <LabeledTextArea
                      name="message"
                      label={t("MESSAGE")}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.message}
                    />
                  </div>
                </div>
                <div className="form__region">
                  <div className="form__row">
                    <LabeledCheckbox
                      name="newsletter"
                      hasError={hasVisibleError("newsletter")}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      defaultChecked={formik.values.newsletter}
                    >
                      <span>
                        <Trans>NEWS_LETTER_DESCRIPTION</Trans>
                      </span>
                    </LabeledCheckbox>
                  </div>
                  <div className="form__row">
                    <LabeledCheckbox
                      name="acceptTerms"
                      hasError={hasVisibleError("acceptTerms")}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      defaultChecked={formik.values.acceptTerms}
                    >
                      {t("FORMS_PRIVACY_TEXT")}{" "}
                      <a
                        href={`/${privacyPath}`}
                        title={t("READ_PRIVACY_DISCLAIMER")}
                        target="_blank"
                        className="link"
                      >
                        {privacyText}
                      </a>
                    </LabeledCheckbox>
                  </div>
                </div>
                {Object.keys(flatErrors).filter((key) => get(formik.touched, key, false)).length >
                  0 && (
                  <div className="form__region form__region--errors">
                    <div className="form__row">
                      <div className="form__group">
                        <p className="form__error-heading">{t("INVALID_FIELDS")}</p>
                        <ul className="list">
                          {Object.keys(flatErrors)
                            .filter((key) => get(formik.touched, key, false))
                            .map((key) => (
                              <li key={key} data-key={key}>
                                {get(flatErrors, key)}
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
                <div className="form__region">
                  <div className="form__row form__row--actions">
                    <div className="form__group">
                      <button type="submit" className="cta" title={t("CONTACT_US")}>
                        {t("CONTACT_US")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          ) : (
            <div className="form-state form-state--success">
              <div className="contact-form__intro">
                <h2 className="contact-form__title">{confirmationTitle}</h2>
              </div>
              <form className="form" name="form--contact-succes" id="form--contact-succes">
                <div className="form__region">
                  <div className="form__row">
                    <div
                      className="form__group form__group--success-message"
                      dangerouslySetInnerHTML={{ __html: confirmationText ?? "" }}
                    ></div>
                  </div>
                </div>

                <div className="form__region">
                  <div className="form__row form__row--actions">
                    <div className="form__group">
                      <button
                        type="button"
                        className="cta"
                        title={t("BACK_TO_CONTACT_FORM")}
                        onClick={openForm}
                      >
                        {t("BACK_TO_CONTACT_FORM")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
