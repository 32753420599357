import React from "react";
import { buildClassName } from "../utils";

interface LabeledCheckboxProps {
  value?: any;
  name: string;
  required?: boolean;
  checked?: boolean;
  defaultChecked?: boolean;
  hasError?: boolean;
  extraClassName?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
}

const LabeledCheckbox: React.FC<LabeledCheckboxProps> = ({
  value,
  name,
  required,
  checked,
  defaultChecked,
  hasError,
  extraClassName,
  onChange,
  onBlur,
  children,
}) => {
  return (
    <div
      className={buildClassName(["form__group", extraClassName, hasError && "form__group--error"])}
    >
      <label className="checkbox">
        <input
          value={value}
          name={name}
          required={required}
          type="checkbox"
          className="checkbox__input"
          onChange={onChange}
          onBlur={onBlur}
          checked={checked}
          defaultChecked={defaultChecked}
        />
        <span>{children}</span>
      </label>
    </div>
  );
};

export default LabeledCheckbox;
