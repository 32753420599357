import React, { useState } from "react";
import { compact, head } from "lodash";
import LightBox from "react-image-lightbox";
import GalleryImage from "./gallery-image";
import { setImageParameters } from "../../utils";

interface GalleryGridProps {
  images: {
    src: string;
    alt?: string;
  }[];
}

const GalleryGrid: React.FC<GalleryGridProps> = ({ images }) => {
  const [imageIndex, setImageIndex] = useState(0);
  const [open, setOpen] = useState(false);

  const highlight = head(images);
  const imageSquares = images.slice(1, 7);
  const remaining = images.length >= 7 ? images.length - 7 : 0;

  const handleImageClick = (index: number) => {
    setImageIndex(index);
    setOpen(true);
  };

  return (
    <div className="gallery-grid">
      {highlight && (
        <div className="gallery-grid__highlight">
          <GalleryImage
            src={setImageParameters(highlight.src, { width: 550, height: 550, mode: "crop" })}
            alt={highlight.alt}
            index={0}
            onClick={handleImageClick}
          />
        </div>
      )}
      <div className="gallery-grid__squares">
        {imageSquares.map((image, i) => (
          <div
            className="gallery-grid__square"
            key={`gallery-grid-square-${i}-${compact([image.src, image.alt]).join("-")}`}
            data-count={i === 5 && remaining > 0 ? `+${remaining}` : undefined}
          >
            <GalleryImage
              src={setImageParameters(image.src, { width: 180, height: 180, mode: "crop" })}
              alt={image.alt}
              index={i + 1}
              onClick={handleImageClick}
            />
          </div>
        ))}
      </div>
      {open && (
        <LightBox
          imageTitle={images[imageIndex]?.alt}
          imageCaption={`${imageIndex + 1} / ${images.length}`}
          mainSrc={images[imageIndex]?.src ?? ""}
          nextSrc={images[(imageIndex + 1) % images.length]?.src ?? ""}
          prevSrc={images[(imageIndex + images.length - 1) % images.length]?.src ?? ""}
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() => setImageIndex((imageIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setImageIndex((imageIndex + 1) % images.length)}
        />
      )}
    </div>
  );
};

export default GalleryGrid;
