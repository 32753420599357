import React, { MouseEventHandler } from "react";
import { compact } from "lodash";

interface MarkerProps {
  id: string;
  lat: number;
  lng: number;
  active?: boolean;
  $hover?: boolean;
  title?: string;
  onClick?: (id: string) => void;
}

const Marker: React.FC<MarkerProps> = ({ id, active, $hover, title, onClick }) => {
  const className = compact(["map-marker", (active || $hover) && "map-marker--large"]).join(" ");

  const handleClick: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    if (onClick) {
      onClick(id);
    }
  };

  return <div className={className} title={title} onClick={handleClick}></div>;
};

export default Marker;
