import { head } from "lodash";
import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import Image from "./image";
import { useI18next } from "gatsby-plugin-react-i18next";

interface MediaSectionProps {
  images: {
    src: string;
    alt?: string;
  }[];
  caption?: string;
}

const MediaSection: React.FC<MediaSectionProps> = ({ images, caption }) => {
  const [open, setOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const highlight = head(images);
  const { t } = useI18next();

  return (
    <div className="media-section">
      <Image
        count={images.length > 1 ? images.length - 1 : undefined}
        type="carousel-highlight"
        src={highlight?.src}
        alt={highlight?.alt}
        title={t("VIEW_IMAGE")}
        onLinkClick={(e) => {
          e.preventDefault();
          setOpen(true);
        }}
      />
      {caption && <p className="caption">{caption}</p>}
      {open && images.length > 0 && (
        <Lightbox
          imageTitle={images[imageIndex]?.alt}
          imageCaption={`${imageIndex + 1} / ${images.length}`}
          mainSrc={images[imageIndex]?.src ?? ""}
          nextSrc={images[(imageIndex + 1) % images.length]?.src ?? ""}
          prevSrc={images[(imageIndex + images.length - 1) % images.length]?.src ?? ""}
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() => setImageIndex((imageIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setImageIndex((imageIndex + 1) % images.length)}
        />
      )}
    </div>
  );
};

export default MediaSection;
