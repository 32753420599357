import React from "react";
import { buildClassName } from "../utils";

interface MediaTextProps {
  title?: string;
  mediaComponent?: React.ReactElement;
  caption?: string;
  reversed?: boolean;
}

const MediaText: React.FC<MediaTextProps> = ({
  title,
  mediaComponent,
  caption,
  reversed,
  children,
}) => (
  <div className={buildClassName(["media-text", reversed && "media-text--reverse"])}>
    <div className="media-text__media">
      {mediaComponent}
      {caption && <div className="caption">{caption}</div>}
    </div>
    <div className="media-text__body">
      <div className="media-text__title">
        <h2>{title}</h2>
      </div>
      <div className="media-text__copy">{children}</div>
    </div>
  </div>
);

export default MediaText;
